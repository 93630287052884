import * as moment from "moment";
import { Exam } from "src/Models/examModel";

export class Print {
    private exam: Exam
    public isPrintLoading: boolean = false

    get isPrintable(): boolean {
        if(!this.exam.tempo_impressao || !this.exam.ultima_data_laudo) {
            return true
        }

        let now: moment.Moment = moment(new Date())
        let end_date: moment.Moment = moment(this.exam.ultima_data_laudo)
        end_date = end_date.add(this.exam.tempo_impressao, 'minutes')

        return (end_date.diff(now) < 0)
    }

    get timeLeft(): string {
        if(this.exam.ultima_data_laudo) {
            let now: moment.Moment = moment()
            let end_date: moment.Moment = moment(this.exam.ultima_data_laudo)

            let end_date_added = end_date.add(this.exam.tempo_impressao, 'minutes')
            let diff = moment.duration(end_date_added.diff(now))

            let days = Math.trunc(diff.asDays())
            let hours = Math.trunc(diff.asHours()) - (days * 24)
            let minutes = Math.trunc(diff.asMinutes()) - ((days * 24 * 60) + (hours * 60))
            
            let return_string = 'Libera em '

            return_string += days && days > 0 ? `${days} dias ` : ''
            return_string += hours && hours > 0 ? `${hours} horas ` : ''
            return_string += minutes && minutes > 0 ? `${minutes} minutos ` : ''

            return return_string
        }

        return ''
    }

    constructor(exam: Exam) {
        this.exam = exam
    }
}