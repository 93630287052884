import { Component, Input, OnInit } from '@angular/core';
import { Anexo } from 'src/Models/anexoModel';
import { Exam } from 'src/Models/examModel';
import { ExamesComponent } from '../../exames.component';

@Component({
  selector: 'app-folha',
  templateUrl: './folha.component.html',
  styleUrls: ['./folha.component.scss']
})
export class FolhaComponent implements OnInit {
  @Input() exam: Exam
  @Input() anexos: Anexo
  @Input() folhas
  @Input() numColuna
  @Input() coluna
  
  
  constructor() { }

  ngOnInit(): void { 
     console.log(this.coluna)
  }

  openAnexo(path: string) {
    open(path, '_blank')
  }

}
