import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/pages/home/home.component';
import { ExamesComponent } from './components/pages/exames/exames.component';
import { VerifyComponent } from './components/pages/verify/verify.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  }, {
    path: 'nologo',
    component: HomeComponent
  }, {
    path: 'exames',
    component: ExamesComponent
  }, {
    path: 'exames/nologo',
    component: ExamesComponent
  }, {
    path: 'verify/:code',
    component: VerifyComponent
  }, {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
