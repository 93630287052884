import { EmailComponent } from './../../pages/exames/email/email.component';
import { Anexo } from './../../../../Models/anexoModel';
import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Exam } from 'src/Models/examModel';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { AnexosComponent } from './anexos/anexos.component';
import { Print } from 'src/app/classes/print';
import { ExamService } from 'src/app/services/exam.service';
@Component({
  selector: 'app-exames',
  templateUrl: './exames.component.html',
  styleUrls: ['./exames.component.scss']
})
export class ExamesComponent implements OnInit {
  exams: Exam[];
  constructor(private router: Router, private dialog: MatDialog, private examService: ExamService) { }
  nologo: boolean = false

  ngOnInit(): void {
    this.exams = JSON.parse(sessionStorage.getItem('exams'))
    this.nologo = window.location.pathname.indexOf('nologo') !== -1;

    if (!this.exams?.length) {
      if (this.nologo)
        this.router.navigate(['/nologo'])
      else
        this.router.navigate(['/'])
    }

    this.exams.forEach(exam => {
      exam.anexos = exam.anexos.filter(anexo => {
        return anexo.is_excluido === false && anexo.is_imagem_chave
      })
      exam.print_controller = new Print(exam)
    })
  }

  backToMenu() {
    sessionStorage.clear()
    if (this.nologo)
      this.router.navigate(['/nologo'])
    else
      this.router.navigate(['/'])
  }

  openViewer(exam: Exam) {
    window.open(`${environment.public_viewer_url}/${exam.viewer_path}`, '')
  }

  openLaudo(exam: Exam) {
    exam.print_controller.isPrintLoading = true
    this.examService.addPrintCount(exam).subscribe(() => {
      exam.print_controller.isPrintLoading = false

      window.open(`${environment.S3_URL}/${exam.laudo[0].pdf_path}`)
    },() => {
      exam.print_controller.isPrintLoading = false
      
    })
  }

  openAnexos(exam: Exam) {
    let dialogOptions = new MatDialogConfig();

    let anexos: Anexo[] = exam.anexos.map(anexo => {
      return {
        file_path: `${environment.S3_URL}/${anexo.file_path}`,
        is_excluido: anexo.is_excluido,
        is_imagem_chave: anexo.is_imagem_chave
      }
    })

    dialogOptions.data = {
      anexos,
      exam
    }

    dialogOptions.width = '100vw'

    this.dialog.open(AnexosComponent, dialogOptions)
  }

  openEmail(exam: Exam) {
    let dialogOptions = new MatDialogConfig();

    dialogOptions.width = '600px'
    dialogOptions.data = exam
    this.dialog.open(EmailComponent, dialogOptions)
  }
}
