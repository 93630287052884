import { Email } from './../../Models/emailModel';
import { Exam } from './../../Models/examModel';
import { Search } from './../../Models/searchModel';
import { environment } from './../../environments/environment'
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExamService {
  exams: Exam[];

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { 
    this.exams = [];
  }

  getExams(data: Search): Observable<Exam[]> {
    const url = environment.api_url + '/results/get-exams'
  
    return this.http.post<any>(url, data, {headers: {api: environment.api}})
  }

  sendEmail(data: Email): Observable<any> {
    const url = environment.api_url + '/results/send-email'

    return this.http.post<any>(url, data, {headers: {api: environment.api}})
  }

  addPrintCount(exam: Exam): Observable<any> {
    const url = environment.api_url + '/results/add-print-count'

    return this.http.post<any>(url, {exam_id: exam.id}, {headers: {api: environment.api}})
  }

  showMessage(msg: string, isError: boolean = false): void {
    this.snackBar.open(msg, 'Fechar', {
      duration: 6000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    })
  }

  getPDF({exam, images, format = undefined, header, footer}) {
    const url = environment.api_key_images + '/generate'
    let exameReq = {...exam}
    delete exameReq.print_controller
    return this.http.post<any>(url, {exam: exameReq, images, format, header, footer}, {responseType: 'blob' as 'json'})
  }
}
