
export const environment = {
  production: true,
  api_url: 'https://integracao.mobilemedvet.com.br',
  public_viewer_url: 'https://portal.mobilemedvet.com.br/#/publicViewer',
  S3_URL: 'https://mobilemedvet-prod.s3.amazonaws.com',
  api: 'mob',
  APP_ANDROID: '',
  APP_IOS: '',
  api_key_images: 'https://gateway.mobilemed.com.br/key-images'
};

