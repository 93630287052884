import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LaudoService } from 'src/app/services/laudo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  code: String
  hasError: boolean = false;

  constructor(private aRoute: ActivatedRoute, private laudoService: LaudoService) {
    this.code = this.aRoute.snapshot.paramMap.get('code')
    this.getLaudo(this.code)
  }

  ngOnInit(): void {
  }

  getLaudo(code: String) {
    this.laudoService.getSigned(code)
      .subscribe(resp => {
        let url = environment.S3_URL + '/' + resp.pdf_path
        window.open(url, '_self')
      }, err => {
        this.hasError = true
      })
  }
}
