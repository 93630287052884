<div style="padding: 10px;" [ngClass]="{'nologo': nologo}">
    <div>
        <div class="button-voltar-mobile" style="margin-bottom: 10px;">
            <button mat-button color="primary" (click)="backToMenu()" style="border-radius: 5px;">
                <span class="material-icons">arrow_back</span>
            </button>
        </div>
        <mat-card>
            <div class="button-voltar-web" style="margin-bottom: 10px; text-align: center;">
                <button mat-raised-button color="primary" (click)="backToMenu()" style="border-radius: 5px;">
                    <span class="material-icons">arrow_back</span>
                    <span class="button-text">Voltar</span>
                </button>
            </div>
            <div class="logo-div">
                <img src="https://mobilemedvet-prod.s3.amazonaws.com/producao/empresa/entrega_logo.png">
            </div>
            <mat-card-title class="title-font">Bem vindo a sua lista de exames!</mat-card-title>
            <mat-card-content class="exam-card">
                <div>
                    <div *ngFor="let exam of exams">
                        <mat-card class="mat-elevation-z0">
                            <mat-card-title>{{exam.nome_paciente}}</mat-card-title>
                            <mat-card-subtitle *ngIf="exam.nome_tutor">Tutor: {{exam.nome_tutor}}
                            </mat-card-subtitle>
                            <mat-card-subtitle *ngIf="exam.peso_paciente">Peso: {{exam.peso_paciente}}
                            </mat-card-subtitle>
                            <mat-card-subtitle *ngIf="exam.raca">Raça: {{exam.raca}}</mat-card-subtitle>
                            <mat-card-subtitle *ngIf="exam.especie">Espécie: {{exam.especie}}</mat-card-subtitle>
                            <mat-card-subtitle *ngIf="exam.idade_paciente">Idade: {{exam.idade_paciente}}
                            </mat-card-subtitle>
                            <mat-card-subtitle *ngIf="exam.estudo_descricao">Estudo: {{exam.estudo_descricao}}
                            </mat-card-subtitle>
                            <mat-card-subtitle>Data de realização: {{exam.data_realizacao | date: 'dd/MM/yyyy,
                                HH:mm'}}
                            </mat-card-subtitle>
                            <mat-card-subtitle class="grid-button" style="margin-top: 15px;">
                                <button mat-raised-button *ngIf="exam.laudo.length" class="btn-success"
                                    [disabled]="!exam.print_controller.isPrintable" (click)="openLaudo(exam)">
                                    <i class="material-icons">assignment</i>
                                    <span class="button-text">Laudo</span>
                                </button>
                                <button style="background-color: #fe7a00; color: white;" mat-raised-button
                                    *ngIf="exam.anexos.length" (click)="openAnexos(exam)">
                                    <span class="material-icons">camera_alt</span>
                                    <span class="button-text">Imagens</span>
                                </button>
                                <button mat-raised-button color="primary" *ngIf="!exam.anexos.length"
                                    (click)="openViewer(exam)" style="background-color: black;">
                                    <i class="material-icons">remove_red_eye</i>
                                    <span class="button-text">Exame</span>
                                </button>
                                <button mat-raised-button color="primary" (click)="openEmail(exam)">
                                    <i class="material-icons">email</i>
                                    <span class="button-text">Enviar Email</span>
                                </button>
                            </mat-card-subtitle>
                        </mat-card>
                    </div>
                </div>

                <!-- <div style="width: 50%;">
                    <img style="width: 450px; height: 450px;"
                        src="https://mobilemedvet-prod.s3.amazonaws.com/producao/empresa/loguinho_cao.jpg">
                </div> -->
            </mat-card-content>
        </mat-card>
    </div>
</div>