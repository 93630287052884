import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/pages/home/home.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { MatCardModule } from '@angular/material/card'
import { MatDividerModule } from '@angular/material/divider'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { HttpClientModule } from '@angular/common/http'
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap'
import { MatSelectModule } from '@angular/material/select';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ExamesComponent } from './components/pages/exames/exames.component';
import { AnexosComponent } from './components/pages/exames/anexos/anexos.component';
import { EmailComponent } from './components/pages/exames/email/email.component';
import { VerifyComponent } from './components/pages/verify/verify.component';
import { FolhaComponent } from './components/pages/exames/anexos/folha/folha.component';

const options: Partial<IConfig> = {
  validation: true,
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ExamesComponent,
    AnexosComponent,
    EmailComponent,
    VerifyComponent,
    FolhaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    NgxMaskModule.forRoot(options),
    HttpClientModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    NgbCarouselModule,
    MatSelectModule,
    MatTooltipModule,
    MatDividerModule,
    ScrollingModule,
    MatIconModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }
}
