<div style="height: 0;" class="scaled">
  <div class="container folha folha-A4 folha-preview" style="align-items: start; padding: 5.5mm;">
    <header class="laudo-header" style="max-height: 200px;">
      <div [innerHtml]="exam.headerSafe">
      </div>
    </header>
    <main style="max-height: 100%;">
      <div  style="max-height: 100%;">
          <div *ngFor="let c of coluna" style="max-height: 100%;">
            <div class="picsum-img-wrapper">
              <div class="grid-column{{numColuna}}" *ngFor="let linha of c.linha">
                <img *ngIf="linha.file_path" class="anexo-images img-{{numColuna}}" src={{linha.file_path}} alt="imagem"
                (click)="openAnexo(linha.file_path)">
                <div *ngIf="!linha.file_path" class="vazio-{{numColuna}}"> </div>
              </div>
            </div>
          </div>
      </div>
    </main>
    <div style="min-width: 100%;">
      <!-- <footer class="laudo-footer">
        <div [innerHtml]="exam.footerSafe">
        </div>
      </footer> -->
    </div>
  </div>
</div>