import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Anexo } from 'src/Models/anexoModel';
import { Exam } from 'src/Models/examModel';
import { Folha } from 'src/Models/folhaModel';
// import * as moment from "moment";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ExamService } from 'src/app/services/exam.service';

@Component({
  selector: 'app-anexos',
  templateUrl: './anexos.component.html',
  styleUrls: ['./anexos.component.scss']
})
export class AnexosComponent implements OnInit {
  folhas: Folha[] = []
  numColuna
  anexos: Anexo[]
  vazio: Anexo[]
  exam: Exam
  pdfLink: SafeResourceUrl
  pdfLinkStr: string
  errorPdf: boolean = false 
  isMobile = innerWidth < 550

  showHeader = true
  showFooter = true

  constructor(public dialogRef: MatDialogRef<AnexosComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private exameService: ExamService, protected _sanitizer: DomSanitizer) {
    dialogRef.addPanelClass('anexos-dialog')
  }

  printDiv() {
    let w = open(this.pdfLinkStr, '_blank')
    setTimeout(() => {
      w.print()

    }, 200)
  }


  ngOnInit(): void {
    this.anexos = this.data.anexos
    this.exam = this.data.exam

    this.getPDF()
  }

  close() {
    this.dialogRef.close();
  }

  async getPDF() {
    this.pdfLinkStr = null
    this.pdfLink = null
    this.errorPdf = false

    let urls = this.anexos.filter(a => a.is_imagem_chave).map(a => {
      return a.file_path
    })

    this.exameService.getPDF({
      exam: this.exam,
      footer: this.showFooter ? this.exam.footer : undefined,
      header: this.showHeader ? this.exam.header : undefined,
      images: urls
    }).subscribe(resp => {
      let binaryData = [];
      binaryData.push(resp);
      let blob = new Blob(binaryData, {type: 'application/pdf'})

      var fileURL = URL.createObjectURL(blob);
      this.pdfLinkStr = fileURL
      this.pdfLink = this._sanitizer.bypassSecurityTrustResourceUrl(fileURL)
      
      if(this.isMobile) {
        this.abrirPDF()
      }
    }, err => {
      this.errorPdf = true
    })
  }

  toggleHeader() {
    this.showHeader = !this.showHeader
    this.getPDF()
  }

  toggleFooter() {
    this.showFooter = !this.showFooter
    this.getPDF()
  }

  abrirPDF() {
    window.open(this.pdfLinkStr, '_blank')
  }
}