<div [ngClass]="{'nologo': nologo}" class="main-container">
    <div class="card-container">
        <mat-card class="mat-elevation-z0" style="border-style: none;">
            <img *ngIf="!nologo" src="https://mobilemedvet-prod.s3.amazonaws.com/producao/dominio/logo_entrega.png" alt="Logo" class="logo">
            <form>
                <mat-form-field>
                    <input matInput placeholder="Protocolo" [(ngModel)]="searchObj.protocolo" name="protocolo">
                </mat-form-field>
                <mat-form-field style="margin-top: 30px">
                  <mat-label>Exame feito em</mat-label>
                  <mat-select [(value)]="searchObj.data">
                    <mat-option *ngFor="let data of datasFilter" [value]="data.value">
                      {{data.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button mat-raised-button color="primary" (click)="search()" *ngIf="!isLoading" style="margin-top: 15px">
                    Buscar o exame do seu PET!
                </button>
                <div class="div-spinner" *ngIf="isLoading">
                    <mat-spinner diameter="30"></mat-spinner>
                </div>
            </form>
        </mat-card>
    </div>
    <div class="main-div img-container">

    </div>
</div>
