import { environment } from './../../environments/environment'
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LaudoService {

  constructor(private http: HttpClient) { }

  getSigned(code: String): Observable<any>{
    const url = environment.api_url + '/results/signed/' + code
  
    return this.http.get<any>(url, {headers: {api: environment.api}})
  }
}
