<h2 mat-dialog-title class="title">Envie um email</h2>
<mat-dialog-content>
    <form>
        <mat-form-field>
            <input matInput placeholder="Digite o email" [(ngModel)]="email" name="email" type="email">
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="close()" color="warn">Fechar</button>
    <button mat-raised-button color="primary" (click)="sendEmail(email)" *ngIf="!isLoading">
        Enviar
    </button>
    <div class="div-spinner" *ngIf="isLoading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
</mat-dialog-actions>