import { ExamService } from './../../../../services/exam.service';
import { Component, OnInit, Inject } from '@angular/core';
import { Exam } from 'src/Models/examModel';
import { Email } from 'src/Models/emailModel';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {
  email: string = ''
  exam: Exam
  isLoading: boolean = false
  constructor(private examService: ExamService, public dialogRef: MatDialogRef<EmailComponent>, @Inject(MAT_DIALOG_DATA) public data: Exam) { }

  ngOnInit(): void {
    this.exam = this.data
  }

  sendEmail(email: string) {

    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    
    if(emailRegex.test(email)) {
      let emailObj:Email = {
        exame_id: this.exam.id,
        email: email
      }
      
      this.isLoading = true
      this.examService.sendEmail(emailObj).subscribe(()=>{
        setTimeout(()=> {
          this.isLoading = false
          this.examService.showMessage('Email enviado com sucesso!')
          this.dialogRef.close()
          }, 1000)
      }, ()=> {
        this.isLoading = false
        this.examService.showMessage('Erro ao enviar o email!')
      })
    } else {
      this.examService.showMessage('Email inválido!')
    }
  }

  close() {
    this.dialogRef.close();
  }
}
