<div class="header" style="display: flex; justify-content: space-between; align-items: center; padding: 10px 10px;">
  <div style="display: flex; align-items: center;">
    <h2 style="color: white; margin-top: auto; margin-bottom: auto;">
      {{anexos.length}} Imagens selecionadas
    </h2>
  </div>

  <div style="margin-right: 10px; display: flex; align-items: center;">
    <button style="color: white;" *ngIf="!isMobile && pdfLink" mat-button (click)="toggleHeader()"
      [attr.title]="showHeader ? 'Esconder Cabeçalho' : 'Mostrar Cabeçalho'">
      <mat-icon svgIcon="page-layout-header"></mat-icon>
      <span style="margin-left: 5px;">{{showHeader ? 'Esconder Cabeçalho' : 'Mostrar Cabeçalho'}}</span>
    </button>
    <button style="color: white;" *ngIf="!isMobile && pdfLink" mat-button (click)="toggleFooter()"
      [attr.title]="showFooter ? 'Esconder Rodapé' : 'Mostrar Rodapé'">
      <mat-icon svgIcon="page-layout-footer"></mat-icon>
      <span style="margin-left: 5px;">{{showFooter ? 'Esconder Rodapé' : 'Mostrar Rodapé'}}</span>
    </button>
    <button style="color: white;" *ngIf="!isMobile && pdfLink" mat-button (click)="printDiv()"
      title="Imprimir">
      <span class="material-icons">print</span>
      <span style="margin-left: 5px;">Imprimir</span>
    </button>
    <div (click)="close()" class="mouseclick" style="color: white; display: flex; align-items: center; height: 100%;">
      <span class="material-icons">close</span>
    </div>
  </div>

</div>

<main class="modal-print" style="height: 100%;">
  <div *ngIf="isMobile && pdfLink && !errorPdf"
    style="height: 635px; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;">
    <mat-icon svgIcon="file-document-check-outline"></mat-icon>
    <p style="margin-top: 5px;">Documento gerado com sucesso!</p>
    <button mat-raised-button color="primary" (click)="abrirPDF()"
      title="Imprimir">
      <mat-icon svgIcon="file-document-outline"></mat-icon>
      <span style="margin-left: 5px;">Abrir Documento</span>
    </button>
  </div>
  <object *ngIf="!isMobile && pdfLink" style="height: 635px; width: 100%;" [data]="pdfLink"></object>
  <div *ngIf="!pdfLink && !errorPdf"
    style="height: 635px; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;">
    <mat-spinner diameter="30"></mat-spinner>
    <p style="margin-top: 5px;">Aguarde! Estamos montando o melhor PDF!</p>
  </div>
  <div *ngIf="errorPdf"
    style="height: 635px; width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;">
    <mat-icon svgIcon="format-page-break"></mat-icon>
    <p style="margin-top: 5px;">Desculpe-nos! Ocorreu um erro ao gerar o PDF</p>
    <button style="margin-top: 5px;" mat-raised-button color="primary" (click)="getPDF()"
      title="Tentar Novamente">
      <mat-icon svgIcon="reload"></mat-icon>
      <span style="margin-left: 5px;">Tentar Novamente</span>
    </button>
  </div>
</main>