<div class="main-div">
    <mat-card class="mat-elevation-z8" *ngIf="!hasError">
        <div class="div-spinner">
            <mat-spinner diameter="70"></mat-spinner>
        </div>
        <mat-card-title>
            Buscando o exame
        </mat-card-title>
    </mat-card>
    <mat-card class="mat-elevation-z8" *ngIf="hasError">
        <div class="div-spinner">
            <span class="material-icons">report_problem</span>
        </div>
        <mat-card-title>
            Não foi possivel encontrar esse exame!
        </mat-card-title>
    </mat-card>
</div>